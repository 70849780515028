import Vue from 'vue'
import App from './App.vue'
import detectMobile from "./utils/DetecMobile";
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.prototype.$mobile = detectMobile;
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
