<template>
  <div id="app">
      <img v-if="isMobile" src="https://lurca.mx/img/movil.jpg" class="img-fluid" alt="pista" style="width: 100% !important; margin-top: -7%;">
      <img v-else-if="!isMobile" src="https://lurca.mx/img/pc.jpg" class="img-fluid" alt="pista" style="width: 100% !important; ">
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
    return {
      isMobile: this.$mobile(),
    }
  },
}
</script>